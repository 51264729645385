/* eslint-disable vue/multiline-html-element-content-newline */
<template>
  <!-- gradient="to bottom, rgba(79, 111, 120, .9), rgba(0,0,0,.4)" -->
  <section id="hero">
    <v-img
      src="https://pacific-screening.imgix.net/rw-apply-oregon/couple_moving.jpg?q=60&rect=800,700,4385,2757&fit=crop&crop=top&w=1400&h=360"
      class="white--text">
      <v-container class="fill-height px-4 py-12">
        <v-responsive class="d-flex" height="100%" max-width="700" width="100%">
          <v-card color="primary" rounded="xl" class="pa-3" style="opacity: 0.8">
            <base-subheading weight="regular" class="text-uppercase text-center" :size="
              $vuetify.breakpoint.smAndDown
                ? 'text-body-2'
                : 'text-h5'
            " title="Building a new class of tenants" />

            <base-heading size="text-h2" class="text-center" title="WELCOME TO APPLY OREGON" weight="medium" />

            <base-body text-class="white--text">
              At Apply Oregon we leverage 30 years of experience
              in the rental screening and tech world of Pacific
              Screening with the extensive tenant education of
              Rent Well to bridge the gaps between property
              managers and applicants.
            </base-body>
            <div class="
                                                      d-flex
                                                      justify-center
                                                      text-h5 text-uppercase
                                                      font-weigth-bold
                                                      mx-auto
                                                      mb-4
                                                  ">
              Start Listing with us Today
            </div>
            <div class="
                                                      d-flex
                                                      align-center
                                                      text-center
                                                  ">
              <base-btn class="mx-auto" bottom height="64" :tile="false" @click="scrollTo('about-our-services')">
                <div class="d-flex flex-column">
                  <span>Discover More</span>
                  <v-icon class="mt-1">mdi-chevron-down-circle</v-icon>
                </div>
              </base-btn>

              <span class="font-weight-bold ml-1 mr-4 my-4">or</span>

              <base-btn :tile="false" height="64" class="mx-auto"
                href="https://www.cognitoforms.com/PacificScreening/ApplyOregonLandlordApplication" target="_blank">
                <div class="d-flex flex-column">
                  <span class="white--text">Get Started Now</span>
                  <v-icon class="mt-1">mdi-chevron-right-circle</v-icon>
                </div>
              </base-btn>
            </div>
          </v-card>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
export default {
  name: "SectionHero",

  provide: {
    theme: { isDark: true },
  },

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";
      console.log(height);
      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
  },
  methods: {
    scrollTo(id) {
      this.$vuetify.goTo(`#${id}`, {
        easing: "easeInOutCubic",
        offset: 100,
        duration: 450,
      });
    },
  },
};
</script>
